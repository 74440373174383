import React, {useState} from "react";
import {Alert, Button, DialogActions, Typography} from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import theme from "../../theme";
import Slide from "@mui/material/Slide";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

/**
 * Component for display helper EAPS popup
 * @param isOnRegisterView if this helper display on register view
 * @returns
 */
const HelperEapsAlert = ({isOnRegisterView = false}) => {

    const [openHelpEaps, setOpenHelpEaps] = useState(false);
    const handleOpenHelpEaps = () => {
        setOpenHelpEaps(true)
    };
    const handleCloseHelpEaps = () => setOpenHelpEaps(false);
    return (
        <Alert severity="info" sx={{mb: 3}}>
            <Typography variant="p" paragraph sx={{mb: 0}}>
                {isOnRegisterView ?
                    "Gagnez du temps sur la création de votre compte " :
                    "Augmentez votre visibilité et votre crédibilité "
                }
                en renseignant votre numéro de carte profesionnelle du ministère des sports. {" "}
                <span
                    className="roboto"
                    style={{
                        border: "none",
                        background: "transparent",
                        color: theme.palette.primary.main,
                        cursor: "pointer",
                        textDecoration: "underline"
                    }}
                    onClick={handleOpenHelpEaps}
                >
                    Consulter l'aide
                </span>
            </Typography>

            <Dialog
                open={openHelpEaps}
                TransitionComponent={Transition}
                keepMounted
                onClose={handleCloseHelpEaps}
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogTitle
                    sx={{textAlign: "center"}}>
                    Suivez les étapes suivantes pour trouver votre numéro de carte profesionnelle
                </DialogTitle>

                <DialogContent sx={{mx: "12px"}}>
                    <ol>
                        <li>
                            Cliquez sur le lien :
                            <a
                                href="http://eapspublic.sports.gouv.fr/CarteProRecherche/Recherche"
                                target="_blank"
                                rel="noreferrer"
                                style={{color: theme.palette.primary.main}}
                            > Ministère des sports
                            </a>
                        </li>
                        <li>
                            Complétez votre nom et votre prénom puis cliquez sur "Rechercher"
                        </li>
                        <li>
                            Un tableau apparait en dessous, cliquez sur votre nom de famille,
                            ce qui va vous rediriger sur la page de votre profil. 
                        </li>
                        <li>
                            Copiez le numéro de votre carte profesionnelle puis retournez sur We Guide You.
                        </li>
                        <li>
                            Collez ce numéro dans le champs prévu à cet effet (Numéro de votre carte profesionnelle)
                        </li>
                        <li>
                            Cliquez sur le bouton "{ isOnRegisterView ? "Charger mes informations" : "Valider" }"
                        </li>
                        {isOnRegisterView &&
                            <li>
                                Vous pouvez désormais finaliser votre inscription.
                            </li>
                        }
                    </ol>

                    <DialogActions>
                        <Button
                            onClick={handleCloseHelpEaps}
                            sx={{color: theme.palette.primary.main}}>
                            Fermer
                        </Button>
                    </DialogActions>
                </DialogContent>
            </Dialog>
        </Alert>
    )
}

export default HelperEapsAlert
