export const validUrlEAPS = (urlEaps) => {
    return urlEaps.length === 11;
}


export const validDateRespectMinDateAndBeforeToday = (dateReceived, dateToCompare, nbDaysMaxBefore) => {
    if (dateReceived !== null  && dateToCompare instanceof Date){
        let parseDateReceived = new Date(dateReceived);
        if (parseDateReceived> dateToCompare){
            return false;
        } else {
            let minDate  = new Date(dateToCompare);
            minDate.setDate(minDate.getDate() - nbDaysMaxBefore);
            return parseDateReceived >= minDate;
        }

    }else {
        return false;
    }
}

export const validDateEndRespectMaxDate = (dateReceived, dateToCompare, nbDaysMaxAfter) => {
    if (dateReceived !== null && dateReceived instanceof Date && dateToCompare instanceof Date){
        if (dateReceived < dateToCompare){
            return false;
        } else {
            let maxDate  = new Date(dateToCompare);
            maxDate.setDate(maxDate.getDate() + nbDaysMaxAfter);

            return dateReceived <= maxDate;
        }

    }else {
        return false;
    }
}
